<template>
    <div class="page-container">
        <md-app md-waterfall md-mode="fixed">
            <md-app-toolbar class="md-primary">
                <div class="md-toolbar-row">
                    <div class="md-toolbar-section-start">
                        <img src="/img/logo-stock.png" alt="stock.ftvaccess.fr" class="logo" />
                        <h3 class="md-title">stock.ftvaccess.fr</h3>
                    </div>
                    <div class="md-toolbar-section-end">
                        <md-menu md-direction="bottom-end" :mdCloseOnClick="true" :mdCloseOnSelect="true">
                            <md-button class="md-icon-button" md-menu-trigger>
                                <md-icon>more_vert</md-icon>
                            </md-button>
                            <md-menu-content>
                                <md-menu-item v.if="$store.getters.logged" @click="$router.push('/stock')">
                                    <span>Recherche de programmes</span>
                                    <md-icon>search</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/password')">
                                    <span>Changer mot de passe</span>
                                    <md-icon>lock</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/confidentialite')">
                                    <span>Politique de confidentialité</span>
                                    <md-icon>policy</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/cgu')">
                                    <span>CGU</span>
                                    <md-icon>description</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/legal')">
                                    <span>Mentions légales</span>
                                    <md-icon>gavel</md-icon>
                                </md-menu-item>
                                <md-menu-item  @click="logout">
                                    <span>Déconnexion</span>
                                    <md-icon>logout</md-icon>
                                </md-menu-item>
                            </md-menu-content>
                        </md-menu>
                    </div>
                </div>
            </md-app-toolbar>
            <md-app-content class="md-scrollbar md-alignment-top-center md-layout">
                <md-card id="card_cgu" md-with-hover class="md-layout-item md-size-100">
                    <md-card-header>
                        <h1 class="md-title">Accepter les conditions générales d'utilisation</h1>
                    </md-card-header>
                    <p>
                        France.tv studio traite vos données personnelles afin de vous permettre d’accéder au contenu et aux services fournis par ce Site. Pour consulter notre Politique de Confidentialité, veuillez cliquer <a href="/confidentialite" target="_blank">ICI</a>. 
                    </p>
                    <md-checkbox v-model="cgu" :value="true">Par ailleurs, en cochant la case, vous acceptez les Conditions Générales d’Utilisation</md-checkbox><br />
                    <br />
                    Vous pouvez suivre le lien vers les <a href="/cgu" target="_blank">Conditions Générales d’Utilisation</a>.
                
                    <md-card-actions v-if="cgu">
                       <br/><br /><br /><br />
                        <md-button class="md-raised" type="submit" @click="submit">Accepter les CGU</md-button>
                    </md-card-actions>
                
                
                </md-card>
            </md-app-content>
        </md-app>
    </div>
</template>
      

<script>

export default {
  name: 'Password',
  data(){
      return {
          loader: null,
          cgu:false,
          form:{
              password:''
          }
      }
  },
  methods:{
      logout(){
            this.$store.dispatch('user/logout')
            .then(() => {
                this.$router.push({path: '/'});
            })
            .catch(() => {
                this.$alert("Il n'a pas été possible de vous deconnecter",'Error','error');
            })
        },
      submit(){
        this.loader=this.$loading.show();
        this.$store.dispatch('user/accept_cgu').then(()=>{
            this.$store.dispatch('user/getInfo').then(()=>{
                this.loader.hide();
                this.$router.push({path: '/stock'})
            })
        })
      }
  }
  
}
</script>
<style>
.md-card#card_cgu{
    min-width: 100%;
  }
</style>
<style lang="scss" scoped>
.md-app {
    min-height: 100vh;
    
    border: 1px solid rgba(#000, .12);
  }
  
img.logo{
    max-height: 50px;
}
.md-layout-item{
    flex-basis: 400px;
    min-width: 300px;
    max-width: 400px;
}
</style>